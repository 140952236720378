import * as React from 'react'

export default function TweetFeed({ handle }) {
    if (!handle) return null

    return (
        <div>
            <a
                className="twitter-timeline"
                data-height="350"
                href={`https://twitter.com/${handle}`}
            >
                Tweets by ${handle}
            </a>
        </div>
    )
}
