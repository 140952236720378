import * as React from 'react'
import { Link } from 'gatsby'
import { PlayIcon } from '@heroicons/react/solid'

export default function ChannelLinks({ data, doc }) {
    if (!data || !doc) return null

    return (
        <ul className="mb-6">
            <li className="mb-2">
                <Link
                    className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                    to={`/iframe-${data.prismicChannel.uid}`}
                >
                    <PlayIcon className="mr-2 w-6" />
                    <span>{`${doc.name.text} en direct sur ordinateur`}</span>
                </Link>
            </li>

            <li className="mb-2">
                {doc.live_channel_android_link?.url.includes(
                    `/iframe-${data.prismicChannel.uid}`
                ) && (
                    <Link
                        className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                        to={`/iframe-${data.prismicChannel.uid}`}
                    >
                        <PlayIcon className="mr-2 w-6" />
                        <span>{`${doc.name.text} en direct sur Android`}</span>
                    </Link>
                )}
                {!doc.live_channel_android_link?.url.includes(
                    `/iframe-${data.prismicChannel.uid}`
                ) && (
                    <a
                        className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                        href={doc.live_channel_android_link?.url}
                    >
                        <PlayIcon className="mr-2 w-6" />
                        <span>{`${doc.name.text} en direct sur Android`}</span>
                    </a>
                )}
            </li>

            <li>
                {doc.live_channel_iphone_link?.url.includes(
                    `/iframe-${data.prismicChannel.uid}`
                ) && (
                    <Link
                        className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                        to={`/iframe-${data.prismicChannel.uid}`}
                    >
                        <PlayIcon className="mr-2 w-6" />
                        <span>{`${doc.name.text} en direct sur iPhone ou iPad`}</span>
                    </Link>
                )}
                {!doc.live_channel_iphone_link?.url.includes(
                    `/iframe-${data.prismicChannel.uid}`
                ) && (
                    <a
                        className="flex items-center px-5 py-4 w-full text-sm font-medium text-white rounded-md bg-blue-700 hover:bg-blue-900"
                        href={doc.live_channel_iphone_link?.url}
                    >
                        <PlayIcon className="mr-2 w-6" />
                        <span>{`${doc.name.text} en direct sur iPhone ou iPad`}</span>
                    </a>
                )}
            </li>
        </ul>
    )
}
