import * as React from 'react'
import { graphql, Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'
import useSWRImmutable from 'swr/immutable'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Similar from '../components/Similar'
import AdSlot from '../components/AdSlot'
import CurrentPrograms from '../components/CurrentPrograms'
import TweetFeed from '../components/TweetFeed'
import ChannelLinks from '../components/ChannelLinks'
import CurrentProgram from '../components/channel/CurrentProgram'

const Channel = ({ data, pageContext }) => {
    const { uid } = data.prismicChannel
    const doc = data.prismicChannel?.data
    const settings = data.prismicGeneralSettings?.data

    const hasPrograms = data.swapi?.channels
        ?.map(c => c.publisher_slug)
        .includes(uid)

    const fetcher = (...args) => fetch(...args).then(res => res.json())
    const { data: apiData } = useSWRImmutable(
        hasPrograms
            ? `${process.env.GATSBY_API_URL}/channels/${uid}/programs/now`
            : null,
        fetcher
    )

    if (!data) return null

    return (
        <Layout>
            <Seo
                title={
                    doc.live_channel_seo_title?.text ||
                    doc.live_channel_title?.text
                }
                description={doc.live_channel_seo_description?.text}
                //ogImage={}
            />
            <div className="md:grid md:grid-cols-3 gap-16 mx-auto px-6 pt-12 pb-10 max-w-7xl">
                <div className="md:col-span-2">
                    <div className="pb-4 border-b border-gray-300">
                        <h1 className="text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {prismicH.asText(doc.live_channel_title?.richText)}
                        </h1>
                    </div>

                    {/* Live Channel Top Article Slot */}
                    <AdSlot
                        html={settings.live_channel_top_article_slot?.text}
                    />

                    <CurrentProgram data={apiData} enabled={hasPrograms} />

                    <div className="mt-4 pb-10 prose prose-lg max-w-none text-gray-600">
                        <PrismicRichText
                            field={doc.live_channel_content?.richText}
                            //serializeHyperlink={PrismicLink}
                        />

                        <div className="pt-2 border-t border-gray-200">
                            {/* Live Channel Top Article Slot */}
                            <AdSlot
                                html={
                                    settings.live_channel_top_article_slot?.text
                                }
                            />

                            <p>
                                Comme {doc.name?.text} est en streaming live sur
                                internet, vous n'avez rien besoin de
                                télécharger. Sur cette chaîne : séries, films,
                                émissions, matchs et bien plus encore à
                                découvrir. Si toutefois vous rencontrez des
                                problèmes pour regarder les direct tv, n'hésitez
                                pas à consulter notre{' '}
                                <Link to="/manuel">manuel d'utilisation</Link>.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="pb-10">
                    <h2 className="mb-4 text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                        Les liens
                    </h2>

                    {/* Live Channel End Article Slot */}
                    <AdSlot html={settings.live_channel_end_article?.text} />

                    <ChannelLinks data={data} doc={doc} />

                    {/* Live Channel Right After Links Slot */}
                    <AdSlot
                        html={
                            settings.live_channel_right_after_links_slot?.text
                        }
                    />

                    <CurrentPrograms
                        data={apiData}
                        channel_name={doc?.name?.text}
                        channel_slug={uid}
                        enabled={hasPrograms}
                    />

                    <TweetFeed handle={doc.twitter_handle?.text} />
                </div>
            </div>

            {/* Live Channel Suggestions Top Slot */}
            <div className="flex justify-center">
                <AdSlot
                    html={settings.live_channel_suggestions_top_slot?.text}
                />
            </div>

            <Similar
                channels={data.channels}
                type="live"
                tops={pageContext.tops}
            />
        </Layout>
    )
}

export default Channel

export const query = graphql`
    query ChannelQuery($uid: String!, $tops_uids: [String]) {
        prismicGeneralSettings {
            data {
                live_channel_top_article_slot {
                    text
                }
                live_channel_top_right_slot {
                    text
                }
                live_channel_right_after_links_slot {
                    text
                }
                live_channel_end_article {
                    text
                }
                live_channel_suggestions_top_slot {
                    text
                }
            }
        }
        prismicChannel(uid: { eq: $uid }) {
            uid
            data {
                name {
                    text
                }
                live_channel_title {
                    text
                    richText
                }
                live_channel_seo_title {
                    text
                }
                live_channel_seo_description {
                    text
                }
                live_channel_content {
                    richText
                }
                live_channel_desktop_link {
                    url
                }
                live_channel_android_link {
                    url
                }
                live_channel_iphone_link {
                    url
                }
                name {
                    text
                }
                twitter_handle {
                    text
                }
            }
        }
        channels: allPrismicChannel(
            filter: { uid: { in: $tops_uids, ne: $uid } }
        ) {
            nodes {
                uid
                data {
                    name {
                        text
                    }
                    image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
        swapi {
            channels {
                publisher_slug
            }
        }
    }
`
